import * as React from "react"

import { Layout } from "components"
import { Seo } from "components/seo"

const IndexPage = () => (
  <Layout>
    <Seo title="Home" />
    <h1>Main page</h1>
  </Layout>
)

export default IndexPage
